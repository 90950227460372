// Validate From Fields
import * as Yup from 'yup';
export const validateFormFields = (values) => {
    let validation = Yup.object({
        name: Yup.string().required('name is required').min(3, 'name min lenght 3').max(20, 'name max length is 20'),
        email: Yup.string().required('email is required').email('email is valied'),
        password: Yup.string().required('password is required')/* .matches(/^[A-Z][a-z0-9]{0,10}$/,'password must start with uppercase ...') */,
        rePassword: Yup.string().required('password is required').oneOf([Yup.ref('password')], 'Password and re password doestn match'),
        phone: Yup.string().required('phone is required').matches(/^01[0125][0-9]{8}$/, 'phone not valid ...'),
    })
    return validation;
};


export const validateLoginForm = (values) => {
    let validation = Yup.object({
        email: Yup.string().required('email is required').email('email is valied'),
        password: Yup.string().required('password is required')/* .matches(/^[A-Z][a-z0-9]{0,10}$/,'password must start with uppercase ...') */,
    })
    return validation;
};

export default {
    validateFormFields,
    validateLoginForm
};