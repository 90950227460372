 
import { IoIosArrowForward as arrow } from "react-icons/io";
import { MdSpaceDashboard as Dashboard } from "react-icons/md";
import { MdLanguage as Language, MdOutlineContactless as contact } from "react-icons/md";
import logout from "../assets/Images/svg/logout.svg";

const icons ={
    Language,
    contact,
    logout,
    Dashboard, 
    arrow, 
 }
export default icons;
