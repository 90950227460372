// ==============================Sidebar======================================
import { ReactComponent as Contact } from '../../assets/svg/call-calling.svg';
import { ReactComponent as Dashboard } from '../../assets/svg/dashboard.svg';
// ===========================================================================
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { ReactComponent as PasswordHidden } from '../../assets/svg/password hidden.svg';
import { ReactComponent as PasswordOpen } from '../../assets/svg/password open.svg';

const LogoSvg = {
    // ==============================Sidebar======================================
    Dashboard,
    Contact,
    // ===========================================================================
    ArrowRight,
    PasswordHidden,
    PasswordOpen

}

export default LogoSvg;
